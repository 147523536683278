import React from 'react';
import DECISION from '../../constants/decisions';
import './decisions.css';

const {
    SPLIT,
    DOUBLE_AFTER_SPLIT,
    DOUBLE_OR_HIT,
    DOUBLE_OR_STAND,
    HIT,
    STAND,
    SURRENDER
} = DECISION;


export default function Decisions({onClick}) {
    return (
        <>
            <div className="decision first" onClick={() => onClick(STAND)}>
                <code>stand</code>
            </div>
            <div className="decision second" onClick={() => onClick(HIT)}>
                <code>hit</code>
            </div>
            <div className="decision third" onClick={() => onClick(DOUBLE_OR_STAND)}>
                <code>double (s)</code>
            </div>
            <div className="decision fourth" onClick={() => onClick(DOUBLE_OR_HIT)}>
                <code>double (h)</code>
            </div>
            <div className="decision fifth" onClick={() => onClick(SPLIT)}>
                <code>split</code>
            </div>
            <div className="decision sixth" onClick={() => onClick(DOUBLE_AFTER_SPLIT)}>
                <code>split (das)</code>
            </div>
            <div className="decision seventh" onClick={() => onClick(SURRENDER)}>
                <code>surrender</code>
            </div>
        </>
    );
}