import React from 'react';
import './score.css';

export default function ScoreBar({ correct, total }) {
    let percentage = Math.floor((100 * correct / total) || 0);

    let style = {
        background: getBackgroundGradientText(percentage)
    };

    return (
        <>
            <div className="score-bar">
                <div className="bar" style={style}>&nbsp;</div>
            </div>
            <div className="score">{ correct } / { total }</div>
        </>
    );
}

function getBackgroundGradientText(percentage) {
    console.log(percentage);
    if (percentage === 0) {
        return 'rgb(221, 221, 221)';
    }

    let color = getColorBasedOnPercentage(percentage);
    let checkpoints = ['rgb(221, 221, 221) 0%'];
    if (percentage < 100) {
        checkpoints.push(`rgb(221, 221, 221) ${100 - percentage - 1}%`);
    }
    checkpoints.push(`${color} ${100 - percentage}%`);
    checkpoints.push(`${color}`);

    return `linear-gradient(to left, ${checkpoints.join(', ')})`
}

function getColorBasedOnPercentage(p) {
    const r = 255 - Math.floor((p / 100) * 255);
    const g = Math.floor((p / 100) * 255);
    const b = 0;

    return `rgb(${r}, ${g}, ${b})`;
}