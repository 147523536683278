import { getOtherSoftCard } from './basic-strategy';

const cards = [2, 3, 4, 5, 6, 7, 8, 9, 10, 'J', 'Q', 'K', 'A']

function pickOne() {
    const i = Math.floor(Math.random() * cards.length);
    return cards[i];
}

export default function deal() {
    const whichToDo = Math.random();

    const dealerHand = pickOne();
    const playerHand = [];

    if (whichToDo < .4) {
        // hard total
        playerHand.push(pickOne());
        playerHand.push(pickOne());
    } else if (whichToDo < .7) {
        // soft total
        playerHand.push('A');
        playerHand.push(pickOne());
    } else {
        // splits
        const one = pickOne();
        playerHand.push(one);
        playerHand.push(one);
    }

    if (playerHand.includes('A')) {
        const other = getOtherSoftCard(playerHand);
        if ([10, 'J', 'Q', 'K'].includes(other)) {
            return deal();
        }
    }

    return { dealerHand, playerHand };

}