import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './react/App';
import * as serviceWorker from './serviceWorker';
import deal from './deal';
import bs from './basic-strategy';

ReactDOM.render(<App
    deal={deal}
    basicStrategy={bs}
    />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

