const DECISION = {
    SPLIT: 'split',
    DOUBLE_AFTER_SPLIT: 'das',
    DOUBLE_OR_HIT: 'doh',
    DOUBLE_OR_STAND: 'dos',
    HIT: 'hit',
    STAND: 'stand',
    SURRENDER: 'sur',
};

export default DECISION;