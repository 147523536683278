import React from 'react';

import { Head, Hit, Stand, Double, DoubleOrStand, Split, Surrender } from './pieces/basic-strategy-chart-cells';


import './basic-strategy.css';

const cells = {
    's': key => <Stand key={key} />,
    'h': key => <Hit key={key} />,
    'p': key => <Split key={key} />,
    'd': key => <Double key={key} />,
    'o': key => <DoubleOrStand key={key} />,
    'u': key => <Surrender key={key} />
}

const chart = {
    'basicstrategycards.com': {
        pairs: {
            //         '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            '(2,2)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(3,3)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(4,4)': [ 'h', 'h', 'h', 'p', 'p', 'h', 'h', 'h', 'h', 'h' ],
            '(5,5)': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            '(6,6)': [ 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h', 'h' ],
            '(7,7)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(8,8)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p' ],
            '(9,9)': [ 'p', 'p', 'p', 'p', 'p', 's', 'p', 'p', 's', 's' ],
            '(T,T)': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            '(A,A)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p' ],
        },
        soft: {
            //           '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Ace + 2': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 3': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 4': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 5': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 6': [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 7': [ 's', 'd', 'd', 'd', 'd', 's', 's', 'h', 'h', 'h' ],
            'Ace + 8': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            'Ace + 9': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
        hard: {
            //           '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Hard 8':  [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 9':  [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 10': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            'Hard 11': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h' ],
            'Hard 12': [ 'h', 'h', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 13': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 14': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 15': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'u', 'h' ],
            'Hard 16': [ 's', 's', 's', 's', 's', 'h', 'h', 'u', 'u', 'u' ],
            'Hard 17+':[ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
    },
    '6D|H17|DAS|NOU': {
        pairs: {
            //         '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            '(2,2)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(3,3)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(4,4)': [ 'h', 'h', 'h', 'p', 'p', 'h', 'h', 'h', 'h', 'h' ],
            '(5,5)': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            '(6,6)': [ 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h', 'h' ],
            '(7,7)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(8,8)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p' ],
            '(9,9)': [ 'p', 'p', 'p', 'p', 'p', 's', 'p', 'p', 's', 's' ],
            '(T,T)': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            '(A,A)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p' ],
        },
        soft: {
            //           '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Ace + 2': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 3': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 4': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 5': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 6': [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 7': [ 'o', 'o', 'o', 'o', 'o', 's', 's', 'h', 'h', 'h' ],
            'Ace + 8': [ 's', 's', 's', 's', 'o', 's', 's', 's', 's', 's' ],
            'Ace + 9': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
        hard: {
            //           '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Hard 5':  [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 6':  [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 7':  [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 8':  [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 9':  [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 10': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            'Hard 11': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd' ],
            'Hard 12': [ 'h', 'h', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 13': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 14': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 15': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 16': [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 17': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            'Hard 18+':[ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
    },
    '6D|S17|DAS|EU': {
        pairs: {
            //         '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            '(2,2)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h' ],
            '(3,3)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'u' ],
            '(4,4)': [ 'h', 'h', 'h', 'p', 'p', 'h', 'h', 'h', 'h', 'h' ],
            '(5,5)': [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            '(6,6)': [ 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'h', 'h', 'u' ],
            '(7,7)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'h', 'h', 'u', 'u' ],
            '(8,8)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'u', 'u' ],
            '(9,9)': [ 'p', 'p', 'p', 'p', 'p', 's', 'p', 'p', 's', 's' ],
            '(T,T)': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            '(A,A)': [ 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p', 'p' ],
        },
        soft: {
            //           '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Ace + 2': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 3': [ 'h', 'h', 'h', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 4': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 5': [ 'h', 'h', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 6': [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Ace + 7': [ 's', 'o', 'o', 'o', 'o', 's', 's', 'h', 'h', 'h' ],
            'Ace + 8': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
            'Ace + 9': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
        hard: {
            //            '2', '3', '4', '5', '6', '7', '8', '9', 'T', 'A'
            'Hard 5':   [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'u' ],
            'Hard 6':   [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'u' ],
            'Hard 7':   [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'u' ],
            'Hard 8':   [ 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 9':   [ 'h', 'd', 'd', 'd', 'd', 'h', 'h', 'h', 'h', 'h' ],
            'Hard 10':  [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h', 'h' ],
            'Hard 11':  [ 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'd', 'h' ],
            'Hard 12':  [ 'h', 'h', 's', 's', 's', 'h', 'h', 'h', 'h', 'u' ],
            'Hard 13':  [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'h', 'u' ],
            'Hard 14':  [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'u', 'u' ],
            'Hard 15':  [ 's', 's', 's', 's', 's', 'h', 'h', 'h', 'u', 'u' ],
            'Hard 16':  [ 's', 's', 's', 's', 's', 'h', 'h', 'u', 'u', 'u' ],
            'Hard 17':  [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 'u' ],
            'Hard 18+': [ 's', 's', 's', 's', 's', 's', 's', 's', 's', 's' ],
        },
    },
    
};

export default class BasicStrategy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gameType: 'basicstrategycards.com',
        };
    }

    render () {
        const { gameType } = this.state;
        return (
            <div className="basic-strategy-chart">
                <h4 className="">4-8 decks, S17, Surrender, Peek</h4>
    
                <Section label="Pairs" data={chart[gameType].pairs}/>
                <Section label="Soft Totals" data={chart[gameType].soft}/>
                <Section label="Hard Totals" data={chart[gameType].hard}/>
                <Legend />
            </div>
        );
    }
}

function Legend() {
    return (
        <div className="table-keys-info">
            <div className="title">Key:</div>
            <div className="key-names">
                <div className="strategy-chart-key">
                    <div className="title green-bg">H</div>
                    <div className="name  green-fg"> Hit</div>
                </div>
                <div className="strategy-chart-key">
                    <div className="title red-bg">S</div>
                    <div className="name  red-fg">Stand</div>
                </div>
                <div className="strategy-chart-key">
                    <div className="title purple-bg">P</div>
                    <div className="name  purple-fg">Split</div>
                </div>
                <div className="strategy-chart-key">
                    <div className="title blue-bg">D</div>
                    <div className="name  blue-fg">Double (or Hit)</div>
                </div>
                <div className="strategy-chart-key">
                    <div className="title blue-bg">DS</div>
                    <div className="name  blue-fg">Double (or Stand)</div>
                </div>
                <div className="strategy-chart-key">
                    <div className="title yellow-bg">U</div>
                    <div className="name  yellow-fg">Surrender</div>
                </div>
            </div>
        </div>
    );
}


class Section extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            collapsing: false,
            expanding: false
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleAnimation = this.toggleAnimation.bind(this);
    }

    toggleCollapse() {
        let {
            collapsed,
            collapsing,
            expanding,
        } = this.state;

        if (expanding || collapsing) return;
        
        if (collapsed) {
            expanding = true;
        } else {
            collapsing = true;
        }

        this.setState({ collapsed, collapsing, expanding }, () => {
            setTimeout(this.toggleAnimation, 500);
        })
    }

    toggleAnimation() {
        const {
            collapsing,
            expanding,
        } = this.state;
        let collapsed;
        if (collapsing && !expanding) {
            collapsed = true;
        } else if (expanding && !collapsing) {
            collapsed = false;
        }

        this.setState({collapsing: false, expanding: false, collapsed})
        //
    }

    render() {
        const heading = [2, 3, 4, 5, 6, 7, 8, 9, 'T', 'A'];
        const { label, data } = this.props;
        const {
            collapsed,
            collapsing,
            expanding,
        } = this.state;
        
        const classes = [];
        if (expanding) {
            classes.push('expanding');
        }
        if (collapsing) {
            classes.push('collapsing');
        }
        if (!expanding && !collapsing) {
            if (collapsed) {
                classes.push('collapsed');
            } else {
                classes.push('expanded');            
            }
        }
        
        let i = 0;
    
        return (
            <>
                <h3 onClick={this.toggleCollapse}><strong>{label}</strong></h3>
                <div className={classes.join(' ')} style={{overflowY: 'hidden'}}>
                    <table width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                            <tr>
                                <td rowSpan="2" width="75px">Your<br />Hand</td>
                                <td colSpan="10">Dealer</td>
                            </tr>
                            <tr>
                                {heading.map(_ => <Head key={`${label}-${_}`}>{_}</Head>)}
                            </tr>
        
                            {Object.keys(data).map(_ => (
                                <tr key={`${label}-${_}`}>
                                    <td>{_}</td>
                                    {data[_].map(action => cells[action](`${label}-${i++}`))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}
