import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import logo from './logo.svg';
import './App.css';
import BasicTrainer from './basic-training/basic-training';
import BasicStrategy from './basic-strategy/basic-strategy';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            deal,
            basicStrategy,
        } = this.props;
        return (
            <Router>
                <nav>
                    <ul className="nav-links">
                        <li>
                            <code>
                                <Link to="/chart">Chart</Link>
                            </code>
                        </li>
                        <li>
                            <code>
                                <Link to="/quiz">Quiz</Link>
                            </code>
                        </li>
                    </ul>
                </nav>

                <div className="App">
                    <Route exact path="/" component={BasicStrategy} />
                    <Route exact path="/chart" component={BasicStrategy} />
                    <Route exact path="/quiz" render={() => <BasicTrainer deal={deal} basicStrategy={basicStrategy} />} />
                    {/* <BasicStrategy /> */}
                    {/* <BasicTrainer deal={deal} basicStrategy={basicStrategy} /> */}
                </div>
            </Router>
        );
    }
}

export default App;
