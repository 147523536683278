import React from 'react';

export function Hit() {
    return ( <td className="strategy-chart-cell green-bg">H</td> );
}

export function Stand() {
    return ( <td className="strategy-chart-cell red-bg">S</td> );
}

export function Split() {
    return ( <td className="strategy-chart-cell purple-bg">P</td> );
}

export function Double() {
    return ( <td className="strategy-chart-cell blue-bg">D</td> );
}

export function DoubleOrStand() {
    return ( <td className="strategy-chart-cell blue-bg">DS</td> );
}

export function Surrender() {
    return ( <td className="strategy-chart-cell yellow-bg">U</td> );
}

export function Head({children}) {
    return ( <td className="strategy-chart-cell">{children}</td> );
}