import DECISION from './constants/decisions';

const {
    SPLIT,
    DOUBLE_AFTER_SPLIT,
    DOUBLE_OR_HIT,
    DOUBLE_OR_STAND,
    HIT,
    STAND,
    SURRENDER
} = DECISION;


export default function basicStrategy(hand, dealer) {
    // before we start, massage the data
    hand = hand.map(_ => Number(_) || _.toUpperCase());
    dealer = Number(dealer) || dealer.toUpperCase();

    // first, can/should I split?
    
    if (hand[0] === 'A' && hand[1] === 'A') {
        return SPLIT;
    }
    if (hand[0] === 9 && hand[1] === 9) {
        if (![7, 10, 'A'].includes(dealer)) {
            return SPLIT;
        }
    }
    if (hand[0] === 8 && hand[1] === 8) {
        return SPLIT;
    }
    if (hand[0] === 7 && hand[1] === 7) {
        if (dealer < 8) {
            return SPLIT;
        }
    }
    if (hand[0] === 6 && hand[1] === 6) {
        if (dealer === 2) {
            return DOUBLE_AFTER_SPLIT;
        } else if (dealer < 7) {
            return SPLIT;
        }
    }
    if (hand[0] === 4 && hand[1] === 4) {
        if (dealer === 5 || dealer === 6) {
            return DOUBLE_AFTER_SPLIT;
        }
    }
    if (hand[0] === 3 && hand[1] === 3) {
        if (dealer < 4) {
            return DOUBLE_AFTER_SPLIT;
        } else if (dealer < 8) {
            return SPLIT;
        }
    }
    if (hand[0] === 2 && hand[1] === 2) {
        if (dealer < 4) {
            return DOUBLE_AFTER_SPLIT;
        } else if (dealer < 8) {
            return SPLIT;
        }
    }

    // if I did not split, do I have an ace?
    if (hand.includes('A')) {
        const other = getOtherSoftCard(hand);
        if (other === 9) {
            return STAND;
        }
        if (other === 8) {
            if (dealer === 6) {
                return DOUBLE_OR_STAND;
            }
            return STAND;
        }
        if (other === 7) {
            if (dealer < 7) {
                return DOUBLE_OR_STAND;
            } else if (dealer < 9) {
                return STAND;
            }
            return HIT;
        }
        if (other === 6) {
            if ([3, 4, 5, 6].includes(dealer)) {
                return DOUBLE_OR_HIT;
            }
            return HIT;
        }
        if (other === 5) {
            if ([4, 5, 6].includes(dealer)) {
                return DOUBLE_OR_HIT;
            }
            return HIT;
        }
        if (other === 4) {
            if ([4, 5, 6].includes(dealer)) {
                return DOUBLE_OR_HIT;
            }
            return HIT;
        }
        if (other === 3) {
            if ([5, 6].includes(dealer)) {
                return DOUBLE_OR_HIT;
            }
            return HIT;
        }
        if (other === 2) {
            if ([5, 6].includes(dealer)) {
                return DOUBLE_OR_HIT;
            }
            return HIT;
        }
    }

    // if I did not split and I don't have an ace, should I surrender?
    const total = totalTwoCards(hand);
    if (total === 16 && [9, 10, 'J', 'Q', 'K', 'A'].includes(dealer)) {
        return SURRENDER;
    }
    if (total === 15 && [10, 'J', 'Q', 'K'].includes(dealer)) {
        return SURRENDER;
    }

    // if I did not surrender, then let's look at the hard total
    if (total >= 17) {
        return STAND;
    }
    if (total === 16 || total === 15 || total === 14 || total === 13) {
        if (dealer < 7) {
            return STAND;
        } else {
            return HIT;
        }
    }
    if (total === 12) {
        if ([4, 5, 6].includes(dealer)) {
            return STAND;
        } else {
            return HIT;
        }
    }
    if (total === 11) {
        return DOUBLE_OR_HIT;
    }
    if (total === 10) {
        if (dealer <= 9) {
            return DOUBLE_OR_HIT;
        } else {
            return HIT;
        }
    }
    if (total === 9 && [3, 4, 5, 6].includes(dealer)) {
        return DOUBLE_OR_HIT;
    }

    // if none of the conditions above are met, hit me
    return HIT;
}

export function getOtherSoftCard(hand) {
    if (hand[0] === 'A') {
        return hand[1];
    } else {
        return hand[0];
    }
}

export function totalTwoCards(hand) {
    return hand.reduce((sum, current) => {
        if (current === 'A') {
            return sum + 11;
        }
        return sum + (Number(current) || 10);
    }, 0);
}