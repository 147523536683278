import React from 'react';
import Card from './card';
import Decisions from './decisions';
import ScoreBar from './score';
import './basic-training.css';

class BasicTrainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            decisionTotal: 0,
            correctTotal: 0,
            dealerHand: null,
            playerHand: [],
            refreshBit: false,
            correctClass: '',
        };

        this.removeCorrectClass = this.removeCorrectClass.bind(this);
        this.refresh = this.refresh.bind(this);
        this.guess = this.guess.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const newHands = this.props.deal();

        this.setState({...newHands, refreshBit: false});
    }

    guess(playerDecision) {
        let { decisionTotal, correctTotal, dealerHand, playerHand, refreshBit } = this.state;
        decisionTotal++;
        let correctClass = 'incorrect';
        const correctDecision = this.props.basicStrategy(playerHand, dealerHand);
        if (playerDecision === correctDecision) {
            correctTotal++;
            refreshBit = true;
            correctClass = 'correct';
        }

        
        if (refreshBit) {
            this.setState({decisionTotal, correctTotal, correctClass}, () => {
                this.refresh();
                setTimeout(this.removeCorrectClass, 500);
            });
        } else {
            this.setState({decisionTotal, correctTotal, correctClass, refreshBit: true}, () => {
                setTimeout(this.removeCorrectClass, 500);
            });
        }
    }

    removeCorrectClass() {
        this.setState({correctClass: ''});
    }

    render() {
        const {
            dealerHand,
            playerHand,
            decisionTotal,
            correctTotal,
            correctClass,
        } = this.state;
        // console.log(`correctTotal: ${correctTotal}`);
        // console.log(`decisionTotal: ${decisionTotal}`);
        return (
            <div className={`basic-trainer section ${correctClass}`}>
                <ScoreBar correct={correctTotal} total={decisionTotal} />
                <section className="basic-trainer-section">
                    <div className="dealer-hand">
                        <Card>{dealerHand}</Card>
                    </div>
                    <div className="player-hand">
                        <Card>{playerHand[0]}</Card>
                        <Card>{playerHand[1]}</Card>
                    </div>
                </section>
                <Decisions onClick={this.guess} />
            </div>
        );

    }



}

export default BasicTrainer;
